import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

export const Context = React.createContext()

function AuthContext(props) {
  const {
    children,
  } = props

  const [auth, setAuth] = useState()
  useEffect(() => {
    fetch('/auth/user')
      .then(rsp => {
        if (!rsp.ok) {
          throw new Error('unauthenticated')
        }
        return rsp.json()
      })
      .then(user => setAuth({ user }))
      .catch(() => setAuth({ error: true }))
  }, [])

  return (
    <Context.Provider value={auth}>
      {children}
    </Context.Provider>
  )
}

AuthContext.propTypes = {
  children: PropTypes.any,
}

AuthContext.defaultProps = {}

export default AuthContext
