import React, { Suspense } from 'react'
import Layout from 'js/components/layout'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import AuthContext from 'js/components/auth_context'
import AuthRedirect from 'js/components/auth_redirect'
import Loading from 'js/components/loading'

const Projects = React.lazy(() => import('js/components/projects'))
const Detail = React.lazy(() => import('js/components/detail'))

function App() {
  return (
    <AuthContext>
      <AuthRedirect>
        <Router>
          <Layout>
            <Switch>
              <Route exact path='/'>
                <Suspense fallback={<Loading />}>
                  <Projects />
                </Suspense>
              </Route>
              <Route path='/projects/:type/:id'>
                <Suspense fallback={<Loading />}>
                  <Detail />
                </Suspense>
              </Route>
            </Switch>
          </Layout>
        </Router>
      </AuthRedirect>
    </AuthContext>
  )
}

App.propTypes = {}

App.defaultProps = {}

export default App
