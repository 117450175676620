import React from 'react'

function Loading() {
  return (
    <div>Loading...</div>
  )
}

Loading.propTypes = {}

Loading.defaultProps = {}

export default Loading
