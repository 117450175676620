import React from 'react'
import PropTypes from 'prop-types'
import { Main } from './styles'

function Layout(props) {
  const {
    children,
  } = props

  return (
    <Main>
      {children}
    </Main>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
}

Layout.defaultProps = {}

export default Layout
