import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Context } from 'js/components/auth_context'

function AuthContext(props) {
  const {
    children,
  } = props

  const auth = useContext(Context)

  useEffect(() => {
    if (auth && auth.error) {
      const newLocation = new URL('/auth/gitlab-oauth/sign-in', window.location)
      newLocation.searchParams.set('redirect', window.location.pathname)
      window.location.assign(newLocation.href)
    }
  }, [auth])

  if (!auth || auth.error) return null

  return children
}

AuthContext.propTypes = {
  children: PropTypes.any,
}

AuthContext.defaultProps = {}

export default AuthContext
